import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import WithTax from "../views/WithTax.vue";
import GamingTax from "../views/GamingTax.vue";
import EDPRN from "../views/EDPRN.vue";
import WHTPRN from "../views/WHTPRN.vue";
import GPRN from "../views/GamingPRN.vue";
import Settings from "../views/Settings.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/excise-duty",
    name: "Excise Duty",
    component: Tables,
  },
  {
    path: "/withholding-tax",
    name: "Withholding Tax",
    component: WithTax,
  },
  {
    path: "/gaming-tax",
    name: "Gaming Tax",
    component: GamingTax,
  },
  {
    path: "/ed-prn",
    name: "Excise Duty PRN",
    component: EDPRN,
  },
  {
    path: "/wht-prn",
    name: "Withholding Tax PRN",
    component: WHTPRN,
  },
  {
    path: "/gaming-prn",
    name: "Gaming Tax PRN",
    component: GPRN,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
